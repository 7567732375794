.blogList-wrap{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    padding-bottom: 4rem; /* Adjust the value according to your design */
}

@media(max-width: 768px){
    .blogList-wrap{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width: 600px){
    .blogList-wrap{
        grid-template-columns: repeat(1, 1fr);
    }
}
