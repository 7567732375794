.contact-button-container {
  text-align: center;
}

.contact-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-button:hover {
    background-color: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}

.contact-box {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: transparent; /* Set background to transparent */
  text-align: center;
}

.contact-box h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #fff; /* White text color */
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Blue button */
  color: #fff; /* White text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
}

.btn-primary:hover {
    background-color: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}
