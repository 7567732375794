.resume {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgb(255, 255, 255);
    object-fit: contain;
}

image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.resume>h1 {
    color: rgb(0, 0, 0);
    font-size: 100px;
    margin-top: -100px;
}

.resume-pages {
    margin-top: 32px;
    height: 100%;
}

.resume-pages .Document {
    margin: 6px;
}

@media screen and (max-width: 960px) {
    .resume>h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .resume>h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .Document {
        width: 100%;
    }
}