.blog-wrap {
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem 1rem; /* Adjusted padding for more top and bottom padding */
}

img[src="null"] {
  display: none;
  size: 1rem;
}

.blog-goBack {
  text-decoration: none;
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  display: block;
}

.blog-wrap header {
  text-align: center;
  margin-bottom: 2rem; /* Increased margin between header and content */
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 1.5rem; /* Increased margin between date and content */
}

.blog-wrap img {
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem; /* Increased margin between image and content */
}

.blog-subCategory {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; /* Increased margin between subCategory and content */
}

.blog-subCategory > div {
  margin: 1.5rem; /* Increased margin around subCategory items */
}

.blog-para {
  padding: 1.5rem; /* Increased padding around description */
  margin-top: 1.5rem;
  /*border: 1px solid #e0e0e0;*/
  margin-bottom: 2rem; /* Increased margin between description blocks */
}

.TailSpin {
  margin: 50px;
  padding: 50px;
  align-items: center;
  position: relative;
  min-height: 65vh;
}