* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
/* .projects, */
.resume,
.contactme {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.projects {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.resume {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
  font-size: 100px;
}

.contactme {
  background-image: url('./images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}