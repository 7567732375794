.searchBar-wrap {
  width: fit-content;
  margin: 0 auto 0rem; /* Remove top margin */
  padding: 2rem;
  border-radius: 4px;
  /* need to add to this to stop search bar going on forrrreverrr */
}

.searchBar-wrap form {
  display: flex;
  align-items: center;
}

.searchBar-wrap input {
  background-color: #f0f0f0;
  outline: none;
  border: none;
  padding: 10px;
  font-size: 20px;
  font-weight: 200;
  text-indent: 8px;
  border-radius: 4px;
  margin-right: 10px;
}

.searchBar-wrap span {
  padding-right: 0.5rem;
  cursor: pointer;
  background-color: #f0f0f0;
  font-size: 24px;
  border-radius: 4px;
}
